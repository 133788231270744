<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">201 MAINTENANCE</h3>
      <!-- <el-button @click="drawer = true">Search Filter</el-button> -->
      <!-- <el-cascader v-model="value" :options="options" :props="{ expandTrigger: 'hover' }" @change="getModule"></el-cascader> -->
    </div>
    <!-- <el-drawer title="Search Filter" :visible.sync="drawer" direction="btt" size="50%">
      <div class="drawer">
        <el-form label-width="100px">
          <el-form-item label="Module">
            <el-select v-model="form.module" placeholder="Select Module" @change="getHeaders">
              <el-option v-for="option in modules" :key="option.id" :label="option.label" :value="option.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Header">
            <el-select v-model="form.header" placeholder="Select Module" @change="getItems">
              <el-option v-for="option in headers" :key="option.id" :label="option.label" :value="option.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Item">
            <el-select v-model="form.item" placeholder="Select Module">
              <el-option v-for="option in items" :key="option.id" :label="option.label" :value="option.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="closeFilterDialog()">Cancel</el-button>
          <el-button type="primary" @click="confirmFilterDialog()">Confirm</el-button>
        </div>
      </div>
    </el-drawer> -->
    <el-dialog title="Add Module" :visible.sync="dialogModule">
      <el-form ref="moduleForm" :model="moduleForm" label-width="120px">
        <el-form-item label="Module Name">
          <el-input v-model="moduleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogModule = false">Cancel</el-button>
        <el-button type="primary" @click="addModule">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Module" :visible.sync="editModuleDialog">
      <el-form ref="editModuleForm" :model="editModuleForm" label-width="120px">
        <el-form-item label="Module Name">
          <el-input v-model="editModuleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editModuleDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateModule">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Add Header" :visible.sync="dialogHeader">
      <el-form ref="headerForm" :model="headerForm" label-width="120px">
        <el-form-item label="Module Name">
          <el-select v-model="headerForm.bg1_id" placeholder="Select Module">
            <el-option v-for="option in modules" :key="option.id" :label="option.name" :value="option.id">
            </el-option>
          </el-select>
          </el-form-item>
        <el-form-item label="Header Name">
          <el-input v-model="headerForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogHeader = false">Cancel</el-button>
        <el-button type="primary" @click="addHeader">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Header" :visible.sync="editHeaderDialog">
      <el-form ref="editHeaderForm" :model="editHeaderForm" label-width="120px">
        <el-form-item label="Header Name">
          <el-input v-model="editHeaderForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editHeaderDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateHeader">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Add Item" :visible.sync="dialogItem">
      <el-form ref="itemForm" :model="itemForm" label-width="120px">
        <el-form-item label="Module Name">
          <el-select v-model="itemForm.bg1_id" placeholder="Select Module" @change="getAddItemHeaderOptions">
            <el-option v-for="option in modules" :key="option.id" :label="option.name" :value="option.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Header Name">
          <el-select v-model="itemForm.bg2_id" placeholder="Select Header">
            <el-option v-for="option in addItemHeaderOptions" :key="option.id" :label="option.name" :value="option.id">
            </el-option>
          </el-select>
          </el-form-item>
        <el-form-item label="Item Name">
          <el-input v-model="itemForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogItem = false">Cancel</el-button>
        <el-button type="primary" @click="addItem">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Item" :visible.sync="editItemDialog">
      <el-form ref="editItemForm" :model="editItemForm" label-width="120px">
        <el-form-item label="Item Name">
          <el-input v-model="editItemForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogModule = false">Cancel</el-button>
        <el-button type="primary" @click="updateItem">Confirm</el-button>
      </span>
    </el-dialog>
    <el-tabs type="border-card">
      <el-tab-pane label="Modules">
        <el-button type="primary" @click="dialogModule = true">Add Module</el-button>
        <el-divider></el-divider>
        <el-collapse accordion class="hidden-md-and-up">
          <el-collapse-item v-for="(module, index) in modules" :key="index" :name="module.id">
            <template slot="title">
              <p style="width: 100%">{{module.name}}</p>
            </template>
            <div class="collapse-buttons">
              <el-tooltip class="item" effect="dark" content="Edit Module" placement="top-start">
                <el-button type="success" icon="el-icon-edit" size="mini" @click="editModule(module)"></el-button>
              </el-tooltip>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-table :data="modules" border class="hidden-sm-and-down">
          <el-table-column label="ID" prop="id" width="100px"></el-table-column>
          <el-table-column label="MODULE NAME" prop="name"></el-table-column>
          <el-table-column label="ACTIONS" fixed="right" width="170">
            <template slot-scope="props">
              <el-button-group>
                <el-button type="success" icon="el-icon-edit" size="mini" @click="editModule(props.row)"></el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Headers">
        <el-button type="primary" @click="dialogHeader = true">Add Header</el-button>
        <el-select v-model="form.module" placeholder="Select Module" @change="getHeaders">
          <el-option v-for="option in modules" :key="option.id" :label="option.name" :value="option.id">
          </el-option>
        </el-select>
        <el-divider></el-divider>
        <el-collapse accordion class="hidden-md-and-up">
          <el-collapse-item v-for="(header, index) in headers" :key="index" :name="header.id">
            <template slot="title">
              <p style="width: 100%">{{header.name}}</p>
            </template>
            <div class="collapse-buttons">
              <el-tooltip class="item" effect="dark" content="Edit Header" placement="top-start">
                <el-button type="success" icon="el-icon-edit" size="mini" @click="editHeader(header)"></el-button>
              </el-tooltip>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-table :data="headers" border class="hidden-sm-and-down">
          <el-table-column label="ID" prop="id" width="100px"></el-table-column>
          <el-table-column label="HEADER NAME" prop="name"></el-table-column>
          <el-table-column label="ACTIONS" fixed="right" width="170">
            <template slot-scope="props">
              <el-button-group>
                <el-button type="success" icon="el-icon-edit" size="mini" @click="editHeader(props.row)"></el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Item">
        <el-button type="primary" @click="dialogItem = true">Add Item</el-button>
        <el-select v-model="form.header" placeholder="Select Header" @change="getItems">
          <el-option v-for="option in headerOptions" :key="option.id" :label="option.name" :value="option.id"></el-option>
        </el-select>
        <el-divider></el-divider>
        <el-collapse accordion class="hidden-md-and-up">
          <el-collapse-item v-for="(item, index) in items" :key="index" :name="item.id">
            <template slot="title">
              <p style="width: 100%">{{item.name}}</p>
            </template>
            <div class="collapse-buttons">
              <el-tooltip class="item" effect="dark" content="Edit Item" placement="top-start">
                <el-button type="success" icon="el-icon-edit" size="mini" @click="editItem(item)"></el-button>
              </el-tooltip>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-table :data="items" border class="hidden-sm-and-down">
          <el-table-column label="ID" prop="id" width="100px"></el-table-column>
          <el-table-column label="ITEM NAME" prop="name"></el-table-column>
          <el-table-column label="ACTIONS" fixed="right" width="170">
            <template slot-scope="props">
              <el-button-group>
                <el-button type="success" icon="el-icon-edit" size="mini" @click="editItem(props.row)"></el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- <el-tab-pane label="Names">
        <el-select v-model="form.item" placeholder="Select Item" @change="getNames">
          <el-option v-for="option in itemOptions" :key="option.id" :label="option.name" :value="option.id"></el-option>
        </el-select>
        <el-divider></el-divider>
        <el-table :data="names" border class="hidden-sm-and-down">
          <el-table-column label="ID" prop="id" width="100px"></el-table-column>
          <el-table-column label="NAME" prop="name"></el-table-column>
        </el-table>
      </el-tab-pane> -->
    </el-tabs>
  </el-card>
</template>

<script>
export default {
  name: 'HR201FileSystem',
  data () {
    return {
      drawer: false,
      search: null,
      value: [],
      modules: [],
      module: null,
      headers: [],
      headerOptions: [],
      addItemHeaderOptions: [],
      header: null,
      items: [],
      itemOptions: [],
      item: null,
      names: [],
      data: [],
      dialogModule: false,
      dialogHeader: false,
      dialogItem: false,
      editModuleDialog: false,
      editHeaderDialog: false,
      editItemDialog: false,
      form: {
        module: null,
        header: null,
        item: null
      },
      moduleForm: {
        name: null
      },
      editModuleForm: {
        id: null,
        name: null
      },
      headerForm: {
        bg1_id: null,
        name: null
      },
      editHeaderForm: {
        id: null,
        name: null
      },
      itemForm: {
        bg1_id: null,
        bg2_id: null,
        objname: 'unknown',
        name: null
      },
      editItemForm: {
        id: null,
        name: null
      }
    }
  },
  mounted () {
    this.getModules()
    this.getHeaderOptions()
    this.getItemsOptions()
  },
  methods: {
    getModules () {
      this.$http
        .get('backend.global1-list')
        .then(res => {
          this.modules = res.data.body
        })
    },
    getHeaders (value) {
      this.$http
        .get(`backend.global2-list/${value}`)
        .then(res => {
          this.headers = res.data.body
        })
    },
    getAddItemHeaderOptions (value) {
      this.$http
        .get(`backend.global2-list/${value}`)
        .then(res => {
          this.addItemHeaderOptions = res.data.body
        })
    },
    getHeaderOptions () {
      this.$http
        .get('backend.global2-list')
        .then(res => {
          this.headerOptions = res.data.body
        })
    },
    getItems (value) {
      this.$http
        .get(`backend.global3-list/${value}`)
        .then(res => {
          this.items = res.data.body
        })
    },
    getItemsOptions () {
      this.$http
        .get('backend.global3-list')
        .then(res => {
          this.itemOptions = res.data.body
        })
    },
    getNames (value) {
      this.$http
        .get(`backend.global4-list/${value}`)
        .then(res => {
          this.names = res.data.body
        })
    },
    addModule () {
      this.dialog = false
      this.$http
        .post('backend.global1', this.moduleForm)
        .then(() => {
          this.getModules()
          this.$message({
            message: 'New Module Added',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    editModule (row) {
      this.editModuleDialog = true
      this.editModuleForm.id = row.id
      this.editModuleForm.name = row.name
    },
    updateModule () {
      this.editModuleDialog = false
      this.$http
        .post('backend.global1-update', this.editModuleForm)
        .then(() => {
          this.getModules()
          this.$message({
            message: 'Module Updated',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    addHeader () {
      this.dialogHeader = false
      this.$http
        .post('backend.global2', this.headerForm)
        .then(() => {
          if (this.form.module) {
            this.getHeaders(this.form.module)
          }
          this.$message({
            message: 'New Header Added',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    editHeader (row) {
      this.editHeaderDialog = true
      this.editHeaderForm.id = row.id
      this.editHeaderForm.name = row.name
    },
    updateHeader () {
      this.editHeaderDialog = false
      this.$http
        .post('backend.global2-update', this.editHeaderForm)
        .then(() => {
          if (this.form.module) {
            this.getHeaders(this.form.module)
          }
          this.$message({
            message: 'Header Updated',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    addItem () {
      this.dialogItem = false
      this.$http
        .post('backend.global3', this.itemForm)
        .then(() => {
          if (this.form.header) {
            this.getItems()
          }
          this.$message({
            message: 'New Item Added',
            type: 'success'
          })
        })
    },
    editItem (row) {
      this.editItemDialog = true
      this.editItemForm.id = row.id
      this.editItemForm.name = row.name
    },
    updateItem () {
      this.editItemDialog = false
      this.$http
        .post('backend.global3-update', this.editItemForm)
        .then(() => {
          if (this.form.header) {
            this.getItems(this.form.header)
          }
          this.$message({
            message: 'Item Updated',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-button {
    margin-right: 1rem;
  }
</style>
